import React, { Component } from "react"
import Particles from "react-particles-js"
import SocialFooter from "../social/SocialFooter"
import { Link } from "gatsby"
import "./footer.css"
import { Row } from "reactstrap"
import scrollTo from "gatsby-plugin-smoothscroll"

class Footer extends Component {
  render() {
    return (
      <footer className="main-footer fixed-footer">
        <div className="footer-inner fl-wrap">
          <div className="container">
            <Particles
              className="partcile-dec"
              params={{
                fps_limit: 28,
                particles: {
                  number: {
                    value: 200,
                    density: {
                      enable: false,
                    },
                  },
                  line_linked: {
                    enable: true,
                    distance: 100,
                    opacity: 0.1,
                  },
                  move: {
                    speed: 1,
                  },
                  opacity: {
                    anim: {
                      enable: true,
                      opacity_min: 0.5,
                      speed: 4,
                      sync: false,
                    },
                    value: 0.9,
                  },
                },
                polygon: {
                  enable: true,
                  scale: 0.5,
                  type: "inline",
                  move: {
                    radius: 10,
                  },
                  inline: {
                    arrangement: "random-point",
                  },
                  draw: {
                    enable: true,
                    stroke: {
                      width: 5,
                      color: "rgba(255, 255, 255, .3)",
                    },
                  },
                },
                retina_detect: true,
              }}
            />

            <Row>
              <div className="col-md-2">
                <div className="footer-title fl-wrap">
                  <span>Jezek Jan</span>
                </div>
              </div>
              <div className="col-md-5">
                <div className="footer-header fl-wrap">
                  <span>01.</span>Neueste Tweets
                </div>
                <div className="footer-box fl-wrap">
                  <div
                    className="twitter-swiper-container fl-wrap"
                    id="twitts-container"
                  ></div>
                  <Link to="/" className="btn float-btn trsp-btn">
                    Folgen
                  </Link>
                </div>
              </div>
              <div className="col-md-5">
                <div className="footer-header fl-wrap">
                  <span>02.</span> Anmelden / Kontakt
                </div>
                <div className="footer-box fl-wrap">
                  <p>
                    Möchten Sie benachrichtigt werden, wenn ich Neuigkeiten habe
                    oder Aktionen anbiete? Melden Sie sich einfach an. Sie
                    können diesen kostenlosen Service jederzeit widerruufen.
                  </p>
                  <div className="subscribe-form fl-wrap">
                    <form id="subscribe" className="fl-wrap">
                      <input
                        className="enteremail"
                        name="email"
                        id="subscribe-email"
                        placeholder="email"
                        spellCheck="false"
                        type="text"
                      />
                      <button
                        type="submit"
                        id="subscribe-button"
                        className="subscribe-button"
                      >
                        <i className="fa fa-paper-plane"></i> Senden
                      </button>
                      <label
                        htmlFor="subscribe-email"
                        className="subscribe-message"
                      ></label>
                    </form>
                  </div>
                  <div className="footer-contacts fl-wrap">
                    <ul>
                      <li>
                        <i className="fal fa-phone"></i>
                        <span>Telefon :</span>
                        <a href="tel:+436505550750">+43 / 650 / 555 0750</a>
                      </li>
                      <li>
                        <i className="fal fa-envelope"></i>
                        <span>Email :</span>
                        <a href="mailto:mail@jezekjan.com">mail@jezekjan.com</a>
                      </li>
                      <li>
                        <i className="fal fa-envelope"></i>
                        <span>Via Formular</span>
                        <Link to="/kontakt">Kontaktformular aufrufen</Link>
                      </li>
                    </ul>
                  </div>
                  <div className="footer-social fl-wrap">
                    <SocialFooter />
                  </div>
                </div>
              </div>
            </Row>
          </div>
        </div>
        <div className="subfooter fl-wrap">
          <div className="container">
            <div className="policy-box">
              <span>
                &#169; Jezek Jan {new Date().getFullYear()} / Alle Rechte
                vorbehalten
              </span>
            </div>
            <a
              href=""
              onClick={() => {
                scrollTo("#wrapper")
              }}
              className="to-top color-bg"
            >
              <i className="fal fa-angle-up"></i>
              <span></span>
            </a>
          </div>
        </div>
      </footer>
    )
  }
}

export default Footer
