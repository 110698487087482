import React, { Component } from "react"
import "./social.scss"

import SocialFooter from "./social-icons"
class Social extends Component {
  render() {
    return (
      <div className="footer-social fl-wrap">
        <SocialIcons />
      </div>
    )
  }
}

export default SocialFooter
